:root {
    --light-color: #8300f8;
    --mid-color: #ab5cf1;
    --dark-color:#fda837 ;
    --size: 30; 
    --trans: ease;
}

.cover {
    height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ccc;
}

.container {
    width: calc(var(--size) * 1vmin);
    height: calc(var(--size) * 1vmin);
    position: relative;
}

.container:before {
    content: "";
    background: var(--mid-color);
    width: 50%;
    height: 50%;
    border-radius:50%;
    position: absolute;
    left: 0%;
    top: 0%;	
    transform-origin: right bottom;
    animation: shadow 4s var(--trans) 0s infinite;
}	

.container:after {
    content: "";
    background: var(--mid-color);
    width: 33.33%;
    height: 33.33%;
    border-radius:50%;
    position: absolute;
    left: 50%;
    top: 16.66%;
    transform-origin: left bottom;
    animation: shadow 4s var(--trans) 1s infinite;
    transform: scale(0);
    z-index: -1;
}

.loader {
    width: calc(var(--size) * 0.5vmin);
    height: calc(var(--size) * 0.5vmin);
    background: var(--dark-color);
    border-radius:50%;
    transform-origin: right bottom;
    animation: outer 4s var(--trans) 0s infinite;
    position: relative;
}

.loader span {
    width: 50%;
    height: 50%;
    border-radius:50%;
    display: block;
    background: var(--light-color);	
    transform-origin: right bottom;
    animation: inner 1s ease-in-out 0.5s infinite;
    top: 50%;
  left: 50%;
  position: absolute;
}

/* a {
    position: absolute;
    font-size: 1.5vmin;
    text-shadow: 0 0px 0 #000;
    bottom: 5vmin;
    font-family: Arial, Helvetica, serif;
    text-decoration: none;
    background: #111;
    color: #fff;
    padding: 1.25vmin 1.5vmin;
    text-transform: uppercase;
}

a:hover {
    background: #999;
    color: #222;
    text-shadow: -1px 0px 0 #fff8;
}

a span {
    color: #454545;
} */

@keyframes outer {
    0% { transform: rotate(0deg) scale(1); }
    12.5% { transform: rotate(90deg) scale(1); }
    25% { transform: rotate(90deg) scale(0.75); }
    37.5% { transform: rotate(180deg) scale(0.75); }
    50% { transform: rotate(180deg) scale(0.5); }
    62.5% { transform: rotate(270deg) scale(0.5); }
    75% { transform: rotate(270deg) scale(0.25); }
    87.5% { transform: rotate(360deg) scale(0.25); }
    100% { transform: rotate(360deg) scale(1); }
}	

@keyframes inner {
    50%, 100% { transform: rotate(360deg) }
}

@keyframes shadow {
    0%, 8.25% { transform: scale(0); }
    16.5% { transform: scale(1); }
    49.5% { transform: scale(0); }
    50% { transform: scale(0) rotate(180deg); }
    50.5%, 58.25% { transform: scale(0) rotate(180deg); }
    66.5% { transform: scale(0.5) rotate(180deg); }
    100% { transform: scale(0) rotate(180deg); }
}